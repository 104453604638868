//@ts-nocheck
import React from "react";
import Image from "next/legacy/image";
import Link from "next/link";
import "tippy.js/dist/tippy.css";
import { useDispatch } from "react-redux";
import { buyModalShow } from "../../redux/modalSlice";
import { useRouter } from "next/router";
import { useContract, useDirectListings } from "@thirdweb-dev/react";
import { ethers } from "ethers";
import { logger } from "../../utils/arbLogger";

const CategoryItem = () => {
  const dispatch = useDispatch();
  const router = useRouter()

  const { data, isLoading, error } = useDirectListings(contract);
  logger.debug(data);

  
	const pid = router.query.user;

  return (
    <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-5">
      {data?.map((item) => {
        const {
          pricePerToken,
          asset,
          assetContractAddress,
          tokenId
        } = item;

        return (
          <article key={tokenId}>
            <div className="group dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-light-base p-[1.1875rem] dark:hover:bg-jacarta-600 hover:bg-jacarta-100">
              <figure className="relative group-hover:scale-105">
                <Link href={`/item/${assetContractAddress}/${tokenId}`}>

                  <Image
                    src={asset?.image}
                    alt="item 5"
                    layout="fill"
                    className="w-full h-[230px] rounded-[0.625rem] object-cover"
                  />

                </Link>

                

                <div className="absolute left-3 -bottom-3">
                  <div className="flex -space-x-2">
                  </div>
                </div>
              </figure>
              <div className="mt-7 flex items-center justify-between ">
                <Link href={`/item/${assetContractAddress}/${tokenId}`}>

                  <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                    {asset.name}
                  </span>

                </Link>
              </div>
              <div className="mt-2 text-sm">
                <span className="dark:text-jacarta-200 flex flex-row text-jacarta-700 mr-1">
                 <img src="/images/arb-icon.png" alt="" width={20} height={20} /> {ethers.utils.formatUnits(pricePerToken)}
                </span>
              </div>

              <div className="mt-8 flex items-center justify-between">
                <button
                  className="text-accent font-display text-sm font-semibold"
                  onClick={() => dispatch(buyModalShow())}
                >
                  Cancel sale
                </button>
              </div>
            </div>
          </article>
        );
      })}
    </div>
  );
};

export default CategoryItem;
