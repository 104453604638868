//@ts-nocheck
import React from "react";
import "tippy.js/dist/tippy.css";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { trpc } from "../../utils/trpc";
import { useAddress } from "@thirdweb-dev/react";
import AssetThumb from "../arbipegs/list/AssetThumb";
import { logger } from "../../utils/arbLogger";
import AuctionAssetThumb from "../arbipegs/list/AuctionAssetThumb";
import { useArbUserMarketplace } from "../../hooks/useArbUserMarketplace";
import { getTopOfferForTokenFromCollection } from "../../utils/transform/offer";
import { ethers } from "ethers";

const CategoryItem = () => {
  const dispatch = useDispatch();
  const router = useRouter()

  const userAddress = router.query.user;
  const walletAddress = useAddress();

  //@ts-ignore
  const { data: ownedNFTs, fetchNextPage } = trpc.user.infiniteOwnedNFTs.useInfiniteQuery({ ownerAddress: userAddress, limit: 25 }, { getNextPageParam: (lastPage) => lastPage.nextCursor });
  //@ts-ignore
  const { data: userMarketplaceData } = useArbUserMarketplace(userAddress);
  logger.trace("USERMARKETPLACE HOOK", userMarketplaceData)
  logger.trace("ONWED INFINITE", ownedNFTs);
  const handleFetchNextPage = () => {
    fetchNextPage();
  };

  return (
    <>
      <div className="grid-container grid grid-cols-2 gap-4 md:gap-5 zes:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xml:grid-cols-6">
        {userMarketplaceData?.directListings?.map((collectionDirectListing) => {
          logger.debug("collectionDirectListing", collectionDirectListing)
          const {
            quantity,
            price: currencyValuePerToken,
            end_timestamp: endTimeInSeconds,
            listing_id: id,
            alchemy: asset,
            contract_address: assetContractAddress,
            creator_address: creatorAddress,
            token_id: tokenId,
            max_offer: topOfferForToken
          } = collectionDirectListing.listing;
          logger.debug("Asset", asset)
          return (
            <AssetThumb key={`assetThumb-${assetContractAddress}-${tokenId}`} id={tokenId} contractAddress={assetContractAddress}
              imageURL={asset?.media[0].thumbnail} name={asset?.metadata.name}
              description={asset?.description}
              unitPrice={ethers.utils.formatUnits(currencyValuePerToken)}
              balance={quantity}
              endTime={endTimeInSeconds}
              listingId={id}
              listingType={"direct"}
              buyButton={(walletAddress?.toLowerCase() == creatorAddress?.toLowerCase()) ? false : true}
              cancelButton={(walletAddress?.toLowerCase() == creatorAddress?.toLowerCase()) ? true : false}
              topOffer={topOfferForToken ? ethers.utils.formatUnits(BigInt(topOfferForToken)) : null}
            />
          );
        })}
        {userMarketplaceData?.englishAuctions?.map((collectionEnglishAuction) => {
          const {
            auction_id: id,
            alchemy: asset,
            contract_address: assetContractAddress,
            creator_address: creatorAddress,
            token_id: tokenId,
            quantity,
            buyoutCurrencyValue,
            currencyContractAddress,
            startTimeInSeconds,
            end_timestamp: endTimeInSeconds,
            minimum_bid_amount: minimumBidCurrencyValue,
            max_offer: topOfferForToken
          } = collectionEnglishAuction.auction;
          return (
            <AuctionAssetThumb key={`assetThumb-${assetContractAddress}-${tokenId}`} id={tokenId} contractAddress={assetContractAddress}
              imageURL={asset?.media[0].thumbnail} name={asset?.metadata.name}
              description={asset?.description}
              unitPrice={ethers.utils.formatUnits(minimumBidCurrencyValue)}
              listingId={id}
              endTime={endTimeInSeconds}
              cancelButton={(walletAddress?.toLowerCase() == creatorAddress?.toLowerCase()) ? true : false}
              bidButton={(walletAddress?.toLowerCase() == creatorAddress?.toLowerCase()) ? false : true}
              topOffer={topOfferForToken ? ethers.utils.formatUnits(BigInt(topOfferForToken)) : null}
            />
          );
        })}
        {ownedNFTs?.pages.map((page, index) => {
          return (<>
            {
              page.nfts?.map((nft) => {
                const {
                  tokenUri,
                  title,
                  description,
                  tokenType,
                  balance,
                  contract,
                  media,
                  tokenId
                } = nft;
                const foundDirectListings = userMarketplaceData?.directListings?.find(onsale => ((onsale.listing.contract_address.toLowerCase() == contract.address.toLowerCase()) && (parseInt(onsale.listing.token_id, 16) == tokenId)));
                logger.debug("OWNED?", tokenId, contract.address, foundDirectListings)
                if (foundDirectListings != null) return (<></>)
                const foundEnglishAuctions = userMarketplaceData?.englishAuctions?.find(onsale => (onsale.auction.contract_address.toLowerCase() == contract.address.toLowerCase() && (parseInt(onsale.auction.token_id, 16) == tokenId)));
                if (foundEnglishAuctions) return (<></>)
                const topOfferForToken = getTopOfferForTokenFromCollection(userMarketplaceData?.offers, contract.address, tokenId)
                return (
                  <AssetThumb
                    key={`owned-${contract.address}-${tokenId}-${index}`}
                    id={tokenId} imageURL={media[0]?.thumbnail}
                    name={title} description={description}
                    contractAddress={contract.address} contractName={contract.name}
                    balance={balance} type={tokenType}
                    sellButton={(walletAddress?.toLowerCase() == userAddress.toLowerCase()) ? true : false}
                    makeOfferButton={(walletAddress?.toLowerCase() == userAddress.toLowerCase()) ? false : true}
                    isOwner={true} topOffer={topOfferForToken}
                  />
                );
              })
            }</>)
        })}
      </div>
      {ownedNFTs?.pages[ownedNFTs?.pages.length - 1]?.nextCursor ?
        <div className="flex flex-row justify-between">
          <button
            className="mt-10 mx-auto  bg-accent hover:bg-accent-dark hover:scale-105 rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
            onClick={() => handleFetchNextPage()}
          >
            Load more...
          </button>
        </div>
        : <></>
      }
    </>
  );
};
export default CategoryItem;
