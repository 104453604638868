import SaleItem from "./salecategoryItem";
import OwnedItem from "./ownedcategoryItem";
import CategoryItem from "./categoryItem";
import { FC } from "react";
interface Props {
    itemDisplay?: string;
  }
  
  const defaultProps: Props = {
    itemDisplay: "category"
  }
  
  const CategoryItemWrapper: FC<Props> = ({itemDisplay}) => {
switch (itemDisplay) {
    case "owned":
      return (<OwnedItem/>)
      break;
    case "sale":
      return (<SaleItem />)
    default:
      return(<CategoryItem />)
  }
}

export default CategoryItemWrapper;