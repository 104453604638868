import React, { FC, useEffect, useState } from "react";
import { tranding_category_filter } from "../../data/categories_data";
import CategoryItemWrapper from "./categoryitemWrapper"
import { trendingCategoryData } from "../../data/categories_data";
import { useDispatch } from "react-redux";
import { updateTrendingCategoryItemData } from "../../redux/counterSlice";
import { useFlags } from 'flagsmith/react';
interface Props {
  itemDisplay?: string;
}

const defaultProps: Props = {
  itemDisplay: "category"
}

const Trending_categories_items: FC<Props> = ({ itemDisplay }) => {
  const [itemdata, setItemdata] = useState(trendingCategoryData);
  const dispatch = useDispatch();
  const [filterVal, setFilterVal] = useState(0);
  const flags = useFlags(['global:trending_category:show', 'user:trending_category:show']); // only causes re-render if specified flag values / traits change
  const global_flag = flags['global:trending_category:show'].enabled;
  const user_flag = flags['user:trending_category:show'].enabled;
  //@ts-ignore
  const handleFilter = (category) => {
    if (category !== "all") {
      setItemdata(
        trendingCategoryData.filter((item) => item.category === category)
      );
    } else {
      setItemdata(trendingCategoryData);
    }
  };

  const sortText = [
    {
      id: 1,
      text: "Recently Added",
    },
    {
      id: 2,
      text: "Price: Low to High",
    },
    {
      id: 3,
      text: "Price: high to low",
    },
    {
      id: 4,
      text: "Auction Ending Soon",
    },
  ];

  useEffect(() => {
    dispatch(updateTrendingCategoryItemData(itemdata.slice(0, 8)));
  }, [itemdata, dispatch]);

  return (
    <>
      {/* <!-- Filter --> */}
      {(!user_flag) ? <></> :

        <div className="mb-8 flex flex-wrap items-center justify-between">
          <ul className="flex flex-wrap items-center">
            {tranding_category_filter.map(({ id, svg, text }) => {
              if (text === "all") {
                return (
                  <li className="my-1 mr-2.5" key={id}>
                    <button
                      className={
                        filterVal === id
                          ? "dark:border-jacarta-600 group bg-accent border-jacarta-100 font-display flex h-9 items-center rounded-lg border px-4 text-sm font-semibold transition-colors border-transparent text-white dark:border-transparent capitalize"
                          : "dark:border-jacarta-600 dark:bg-jacarta-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize"
                      }
                      onClick={() => {
                        handleFilter(text);
                        setFilterVal(id);
                      }}
                    >
                      {text}
                    </button>
                  </li>
                );
              } else {
                return (
                  <li className="my-1 mr-2.5" key={id}>
                    <button
                      onClick={() => {
                        handleFilter(text);
                        setFilterVal(id);
                      }}
                    >
                      <div
                        className={
                          filterVal === id
                            ? "dark:border-jacarta-600 group bg-accent border-jacarta-100 font-display flex h-9 items-center rounded-lg border px-4 text-sm font-semibold transition-colors border-transparent text-white dark:border-transparent capitalize"
                            : "dark:border-jacarta-600 dark:bg-jacarta-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize"
                        }
                      >
                        <svg
                          className={
                            filterVal === id
                              ? "icon mr-1 h-4 w-4 transition-colors fill-white"
                              : "icon fill-jacarta-700 dark:fill-jacarta-100 mr-1 h-4 w-4 transition-colors group-hover:fill-white"
                          }
                        >
                          <use xlinkHref={`/icons.svg#icon-${svg}`}></use>
                        </svg>
                        <span>{text}</span>
                      </div>
                    </button>
                  </li>
                );
              }
            })}
          </ul>
          {/* dropdown */}
        </div>
      }

      {/* <!-- Grid --> */}
      <CategoryItemWrapper itemDisplay={itemDisplay} />
    </>
  );
};

export default Trending_categories_items;
