//@ts-nocheck
import { useState, useEffect } from "react";
import { IArbMarketplace } from "../type/arbipegs";
import { logger } from "../utils/arbLogger";
import { useDispatch } from "react-redux";
import { trpc } from "../utils/trpc";


export const useArbUserMarketplace = (walletAddress: string) => {
    const dispatch = useDispatch();
    const [data, setData] = useState<IArbMarketplace>();
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [fromApis, setFromApis] = useState<boolean>(false);
    const [fromRedis, setFromRedis] = useState<boolean>(false);
    const [fromRedux, setFromRedux] = useState<boolean>(false);

    logger.debug("ACTIVE CHAIN  AND MARKETPLACE CONTRACT:", process.env.NEXT_PUBLIC_ACTIVE_CHAIN_ID, process.env.NEXT_PUBLIC_MARKETPLACE_CONTRACT_ADDRESS)
    const { data: userAllValidData, isLoading: userAllValidLoading, isFetched: userAllValidFetched } = trpc.indexer.userAllValid.useQuery({ walletAddress: walletAddress });
    logger.trace("USER_ALL_VALID", walletAddress, userAllValidData, userAllValidFetched, userAllValidLoading)
    useEffect(() => {
        if (userAllValidFetched) {

            let arbMarketplace: IArbMarketplace = {
                address: walletAddress,
                tokenId: "",
                directListings: userAllValidData.listings ? userAllValidData.listings : [],
                englishAuctions: userAllValidData.auctions ? userAllValidData.auctions : [],
                offers: userAllValidData.offers ? userAllValidData.offers : [],
                isListed: false,
                isAuctioned: false,
                isMarketplaced: false,
                hasOffers: false,
            }
            arbMarketplace.isListed = (arbMarketplace.directListings.length > 0) ? true : false
            arbMarketplace.isAuctioned = (arbMarketplace.englishAuctions.length > 0) ? true : false
            arbMarketplace.isMarketplaced = arbMarketplace.isAuctioned || arbMarketplace.isListed
            arbMarketplace.hasOffers = (arbMarketplace.offers.length > 0) ? true : false
            logger.trace("TRACE IN EFFECT API DO")
            setData(arbMarketplace);
            setLoading(false);
            setFromApis(true);
            //Refresh Redux Cache
            //dispatch(cacheToken(arbToken));
            //Refresh Redis Cache
            //mutation.mutate({ "key": walletAddress+":"+tokenId, "value": JSON.stringify(arbToken) });

        }

    }, [walletAddress, userAllValidFetched, userAllValidData]);


    return { data: data, error: error, isLoading: loading, fromApis: fromApis, fromRedux: fromRedux, fromRedis: fromRedis };
}
